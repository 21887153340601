import React from 'react';
import { SocialIcon } from 'react-social-icons';
import Particles from 'react-tsparticles';

import './comingsoon.styles.css'
import logo from '../../assets/krb2.png'; // with import
import Maps from '../../assets/maps.svg'
const ComingSoon = () => {

    return (
      <>
        <Particles id="tsparticles" options={{
    "particles": {
      "number": {
        "value": 25,
        "density": {
          "enable": true,
          "value_area": 800
        }
      },
      "color": {
        "value": "#ffffff"
      },
      "shape": {
        "type": "circle",
        "stroke": {
          "width": 0,
          "color": "#000000"
        },
        "polygon": {
          "nb_sides": 1
        }
      },
      "opacity": {
        "value": 0.5,
        "random": false
      },
      "size": {
        "value": 3,
        "random": true
      },
      "line_linked": {
        "enable": true,
        "distance": 200,
        "color": "#ffffff",
        "opacity": 0.4,
        "width": 1
      },
      "move": {
        "enable": true,
        "speed": 2,
        "direction": "none",
        "random": false,
        "straight": false,
        "out_mode": "out",
        "bounce": false,
        "attract": {
          "enable": false,
          "rotateX": 600,
          "rotateY": 1200
        }
      }
    }
        }}/>
        <div className="os-phrases" id="os-phrases">
          <h2 style={{opacity: 1}}><span className="word1"><span className="char1"><span className="char1">P</span></span><span className="char2"><span className="char1">r</span></span><span className="char3"><span className="char1">ó</span></span><span className="char4"><span className="char1">x</span></span><span className="char5"><span className="char1">i</span></span><span className="char6"><span className="char1">m</span></span><span className="char7"><span className="char1">a</span></span><span className="char8"><span className="char1">m</span></span><span className="char9"><span className="char1">e</span></span><span className="char10"><span className="char1">n</span></span><span className="char11"><span className="char1">t</span></span><span className="char12"><span className="char1">e</span></span></span> </h2>
        </div>
        <div className='container-image'>
            <img src={logo} className='blur' alt='logo'/>
        </div>
        <div className="container-info">
          <div className="container-rrss">
            <SocialIcon  url="https://es-es.facebook.com/pages/category/Company/Krebor-Technology-SL-115613045196680/" />
            <SocialIcon  url="mailto:krebor@krebor.es" />
            <a href="https://goo.gl/maps/PFtC8yWFrPTUeyBdA">
              <img className="icono-maps" src={Maps} alt='icono maps'/>
            </a>
          </div>
            <br/>
          <div className='container-email' >krebor@krebor.es</div>
            
        </div>
      </>
    );
  };
  /*
    <h2 style={{opacity: 1}}><span className="word1"><span className="char1"><span className="char1">k</span></span><span className="char2"><span className="char1">r</span></span><span className="char3"><span className="char1">E</span></span><span className="char4"><span className="char1">B</span></span><span className="char5"><span className="char1">O</span></span><span className="char6"><span className="char1">R</span></span></span><span className="word2"><span className="char1"><span className="char1">T</span></span><span className="char2"><span className="char1">E</span></span><span className="char3"><span className="char1">C</span></span><span className="char4"><span className="char1">H</span></span><span className="char5"><span className="char1">N</span></span><span className="char6"><span className="char1">O</span></span><span className="char7"><span className="char1">L</span></span><span className="char8"><span className="char1">O</span></span><span className="char9"><span className="char1">G</span></span><span className="char10"><span className="char1">Y</span></span></span> </h2>
  */
  export default ComingSoon;
  