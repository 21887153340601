import React from 'react';
import {Routes, Route} from 'react-router-dom';
import { HeadProvider, Title, Link, Meta } from 'react-head';

import './App.css';

import HomePage from './pages/home/home.component';
import ShopPage from './pages/shop/shop.component';
import ContactPage from './pages/contact/contact.component';
import ComingSoon from './pages/comingsoon/comingsoon.component';

import Header from './components/header/header.component';


const App = () => {

  return (
    <>
      <HeadProvider>
          <Title>Krebor Technology</Title>
          <Meta property="og:title" content="Krebor Technology" />
          {/*<Meta property="og:url" content="Krebor Technology" />*/}
          <Meta name="twitter:title" content="Krebor Technology" />
      </HeadProvider>
      {/*<Header/>
      <Routes>
        <Route exact path='/' component={HomePage} />
        <Route exact path='/shop' component={ShopPage} />
        <Route exact path='/contact' component={ContactPage} />
      </Routes>*/}
      <ComingSoon/>
    </>
  );
};


export default App;
